/* CSRF ENDPOINT TYPES */
/* 
    /csrf Endpoint 
*/
export const GET_CSRF_REQUEST = "GET_CSRF_REQUEST";
export const GET_CSRF_SUCCESS = "GET_CSRF_SUCCESS";
export const GET_CSRF_FAILURE = "GET_CSRF_FAILURE";

/* LOGGEDINAS ENDPOINT TYPES */
/*
    /loggedInAs Endpoint
*/
export const GET_LOGGEDINAS_REQUEST = "GET_LOGGEDINAS_REQUEST";
export const GET_LOGGEDINAS_SUCCESS = "GET_LOGGEDINAS_SUCCESS";
export const GET_LOGGEDINAS_FAILURE = "GET_LOGGEDINAS_FAILURE";

/* REGISTER ENDPOINT TYPES */
/*
    /register Endpoint
*/
export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

/* CONFIRM ENDPOINT TYPES */
/*
    /confirm/<string:confirmation_code> Endpoint
*/
export const POST_CONFIRM_REQUEST = "POST_CONFIRM_REQUEST";
export const POST_CONFIRM_SUCCESS = "POST_CONFIRM_SUCCESS";
export const POST_CONFIRM_FAILURE = "POST_CONFIRM_FAILURE";

/*
    /confirm/resend Endpoint
*/
export const POST_RESEND_CONFIRMATION_REQUEST =
  "POST_RESEND_CONFIRMATION_REQUEST";
export const POST_RESEND_CONFIRMATION_SUCCESS =
  "POST_RESEND_CONFIRMATION_SUCCESS";
export const POST_RESEND_CONFIRMATION_FAILURE =
  "POST_RESEND_CONFIRMATION_FAILURE";

/* LOGIN ENDPOINT TYPES */
/*
    /login Endpoint
*/
export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

/* LOGOUT ENDPOINT TYPES */
/*
    /logout Endpoint
*/
export const POST_LOGOUT_REQUEST = "POST_LOGOUT_REQUEST";
export const POST_LOGOUT_SUCCESS = "POST_LOGOUT_SUCCESS";
export const POST_LOGOUT_FAILURE = "POST_LOGOUT_FAILURE";

/* USERS ENDPOINT TYPES */
/*
    /users/<int:specialty_id>&<int:radius>&<int:page>&<string:sort_by> Endpoint
*/
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

/*
    /users/<string:user_id>/profile Endpoint
*/
// GET requests
export const GET_USERS_PROFILE_REQUEST = "GET_USERS_PROFILE_REQUEST";
export const GET_USERS_PROFILE_SUCCESS = "GET_USERS_PROFILE_SUCCESS";
export const GET_USERS_PROFILE_FAILURE = "GET_USERS_PROFILE_FAILURE";
// PUT requests
export const PUT_USERS_PROFILE_REQUEST = "PUT_USERS_PROFILE_REQUEST";
export const PUT_USERS_PROFILE_SUCCESS = "PUT_USERS_PROFILE_SUCCESS";
export const PUT_USERS_PROFILE_FAILURE = "PUT_USERS_PROFILE_FAILURE";

/*
    /users/<string:user_id>/profile_pic Endpoint
*/
export const POST_PROFILEPIC_REQUEST = "POST_PROFILEPIC_REQUEST";
export const POST_PROFILEPIC_SUCCESS = "POST_PROFILEPIC_SUCCESS";
export const POST_PROFILEPIC_FAILURE = "POST_PROFILEPIC_FAILURE";

/*
    /users/settings/password_reset Endpoint
*/
export const POST_USERS_SETTINGS_PASSWORDRESET_REQUEST =
  "POST_USERS_SETTINGS_PASSWORDRESET_REQUEST";
export const POST_USERS_SETTINGS_PASSWORDRESET_SUCCESS =
  "POST_USERS_SETTINGS_PASSWORDRESET_SUCCESS";
export const POST_USERS_SETTINGS_PASSWORDRESET_FAILURE =
  "POST_USERS_SETTINGS_PASSWORDRESET_FAILURE";

/*
    /users/forgot_password/request Endpoint
*/
export const POST_FORGOTPASSWORD_REQUEST = "POST_FORGOTPASSWORD_REQUEST";
export const POST_FORGOTPASSWORD_SUCCESS = "POST_FORGOTPASSWORD_SUCCESS";
export const POST_FORGOTPASSWORD_FAILURE = "POST_FORGOTPASSWORD_FAILURE";

/*
    /users/forgot_password/update/<string:token> Endpoint
*/
export const POST_USERS_FORGOTPASSWORD_UPDATE_REQUEST =
  "POST_USERS_FORGOTPASSWORD_UPDATE_REQUEST";
export const POST_USERS_FORGOTPASSWORD_UPDATE_SUCCESS =
  "POST_USERS_FORGOTPASSWORD_UPDATE_SUCCESS";
export const POST_USERS_FORGOTPASSWORD_UPDATE_FAILURE =
  "POST_USERS_FORGOTPASSWORD_UPDATE_FAILURE";

/*
    /users/<string:user_uuid>/profile/admin Endpoint
*/
export const GET_USERS_PROFILE_ADMIN_REQUEST =
  "GET_USERS_PROFILE_ADMIN_REQUEST";
export const GET_USERS_PROFILE_ADMIN_SUCCESS =
  "GET_USERS_PROFILE_ADMIN_SUCCESS";
export const GET_USERS_PROFILE_ADMIN_FAILURE =
  "GET_USERS_PROFILE_ADMIN_FAILURE";

export const PUT_USERS_PROFILE_ADMIN_REQUEST =
  "PUT_USERS_PROFILE_ADMIN_REQUEST";
export const PUT_USERS_PROFILE_ADMIN_SUCCESS =
  "PUT_USERS_PROFILE_ADMIN_SUCCESS";
export const PUT_USERS_PROFILE_ADMIN_FAILURE =
  "PUT_USERS_PROFILE_ADMIN_FAILURE";

/*
  /users/accept_admin_request/<string:accept_token> Endpoint
*/
export const POST_USERS_ACCEPT_ADMIN_REQUEST =
  "POST_USERS_ACCEPT_ADMIN_REQUEST";
export const POST_USERS_ACCEPT_ADMIN_SUCCESS =
  "POST_USERS_ACCEPT_ADMIN_SUCCESS";
export const POST_USERS_ACCEPT_ADMIN_FAILURE =
  "POST_USERS_ACCEPT_ADMIN_FAILURE";

/*
  /users/reject_admin_request/<string:accept_token> Endpoint
*/
export const POST_USERS_REJECT_ADMIN_REQUEST =
  "POST_USERS_REJECT_ADMIN_REQUEST";
export const POST_USERS_REJECT_ADMIN_SUCCESS =
  "POST_USERS_REJECT_ADMIN_SUCCESS";
export const POST_USERS_REJECT_ADMIN_FAILURE =
  "POST_USERS_REJECT_ADMIN_FAILURE";

/*
    /users/<string:user_uuid>/user_type Endpoint
*/
export const PUT_USERS_USER_TYPE_REQUEST = "PUT_USERS_USER_TYPE_REQUEST";
export const PUT_USERS_USER_TYPE_SUCCESS = "PUT_USERS_USER_TYPE_SUCCESS";
export const PUT_USERS_USER_TYPE_FAILURE = "PUT_USERS_USER_TYPE_FAILURE";

/* 
  DROPDOWN LIST ENDPOINTS TYPES-> 
  SPECIALTIES, DESIGNATIONS, LANGUAGES 
*/

/* SPECIALTIES ENDPOINT TYPES */
/*
    /specialties Endpoint
*/
export const GET_SPECIALTIES_REQUEST = "GET_SPECIALTIES_REQUEST";
export const GET_SPECIALTIES_SUCCESS = "GET_SPECIALTIES_SUCCESS";
export const GET_SPECIALTIES_FAILURE = "GET_SPECIALTIES_FAILURE";

/* DESIGNATIONS ENDPOINT TYPES */
/*
    /designations Endpoint
*/
export const GET_DESIGNATIONS_REQUEST = "GET_DESIGNATIONS_REQUEST";
export const GET_DESIGNATIONS_SUCCESS = "GET_DESIGNATIONS_SUCCESS";
export const GET_DESIGNATIONS_FAILURE = "GET_DESIGNATIONS_FAILURE";

/* LANGUAGES ENDPOINT TYPES */
/*
    /languages Endpoint
*/
export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAILURE = "GET_LANGUAGES_FAILURE";
